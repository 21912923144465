<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon icon-left">{{t('10030')}}</i>
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub find">
			<!-- contact -->
			<section class="content">
				<div class="row">
					<div class="inner">
						<div class="inner__top">
							<h2 class="title">
								{{t('10235')}}
							</h2>
						</div>
						<div class="inner__wrap">
							<div class="frms">
								<!-- 비밀번호 확인 -->
								<div class="frms__item">
									<h3 class="frms__title">{{t('10236')}}</h3>
									<div class="frms__wrap" :class="{success: isInEnglish && isInNumber && isInLength}">
										<input :type="showPassword ? 'text' : 'password'" class="ipt-frm" :placeholder="t('10772')" v-model="password" maxlength="20" @keyup="checkPasswordFormat"><!-- 재번역 체크 -->
										<div class="frms__btns">
											<button type="button" class="btn-frmreset" @click="password = ''">
												<i class="icon icon-closesmall"></i>
											</button>
											<button type="button" class="btn-frmview" @click="showPassword = !showPassword">
												<i class="icon icon-view" :class="{'disabled': !showPassword}"></i>
											</button>
                						</div>
									</div>
									<div class="frms__check">
										<span :class="{success: isInEnglish}">
											{{t('10133')}}
											<i class="icon icon-check--light"></i>
										</span>
										<span :class="{success: isInNumber}">
											{{t('10134')}}
											<i class="icon icon-check--light"></i>
										</span>
										<span :class="{success: isInLength}">
											{{t('10135')}}
											<i class="icon icon-check--light"></i>
										</span>
									</div>
								</div>
								<!-- 비밀번호 확인 입력시 닫기, 보기버튼 표시 -->
								<div class="frms__item">
									<h3 class="frms__title">{{t('10237')}}</h3>
									<!-- [D] 통과할 경우 success 클래스 추가 -->
									<div class="frms__wrap" :class="{success: isSamePassword}">
										<input :type="showConfirmPassword ? 'text' : 'password'" class="ipt-frm" :placeholder="t('10831')" v-model="confirmPassword" maxlength="20"><!-- 재번역 체크 -->
										<div class="frms__btns">
											<button type="button" class="btn-frmreset" @click="confirmPassword = ''">
												<i class="icon icon-closesmall"></i>
											</button>
											<button type="button" class="btn-frmview" @click="showConfirmPassword = !showConfirmPassword">
												<i class="icon icon-view" :class="{'disabled': !showConfirmPassword}"></i>
											</button>
										</div>
									</div>
									<div class="frms__check">
										<!-- [D] 통과할 경우 success 클래스 추가 -->
										<span :class="{success: isSamePassword}">
											{{t('10137')}}
											<i class="icon icon-check--light"></i>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<!-- [D] 다음 불가 시 -->
		<div class="btns btns--centered">
			<button 
				type="button" 
				class="btn" 
				:class="isInEnglish && isInNumber && isInLength && isSamePassword 
					? 'btn-confirm' 
					: 'btn-disabled'" 
				@click="onClickPasswordChange"
				>
					{{t('10235')}}
				</button>
		</div>
	</div>
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
	<TheNavigation></TheNavigation>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref, computed } from "vue"
import { useStore } from "vuex"
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {
	components: {
		ErrorAlert,
	},
	setup() {
		const router = useRouter();
		const store = useStore();
		const openErrorAlert = ref(false)
		const password = ref("");
		const showPassword = ref(false);
		const confirmPassword = ref("");
		const showConfirmPassword = ref(false);

		const isInEnglish = ref(false);
		const isInNumber = ref(false);
		const isInLength = ref(false);

		const email = computed(() => store.state.mmb03.mmb03);
		const phoneNumber = computed(() => store.state.mmb03.phoneNumber2);
		const isSamePassword = computed(() => password.value.length > 0 && password.value === confirmPassword.value);


		const checkPasswordFormat = () => {
			if(password.value.match(/^(?=.*[a-zA-Z])/)){
				isInEnglish.value = true;
			}else {
				isInEnglish.value = false;
			}
			if(password.value.match(/^(?=.*[0-9])/)) {
				isInNumber.value = true;
			}else {
				isInNumber.value = false;
			}
			if(password.value.length >= 8 && password.value.length <= 20) {
				isInLength.value = true;
			}else {
				isInLength.value = false;
			}
		}

		const onClickPasswordChange = () => {
			if(!isSamePassword.value) { return; }
			if(!(isInEnglish.value && isInNumber.value && isInLength.value)){ return }
				
			store.dispatch("mmb03/fetchMmb0303", {
				"mem_id" : email?.value[0]?.mem_id,
				"hp_no" : phoneNumber.value,
				"passwd" : password.value
			}).then(data => {
				////console.log(data.data.Res_cd)

				if(data.data.Res_cd === "0000"){
					router.push("/member/find/password/complete")
				}
				else {
					//alert(data.data.Res_msg)
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: data.data.Res_msg});
					openErrorAlert.value = true;
				}
			});
		};

		const goBack = () => {
			router.go(-1);
		};
		const { t }= useI18n() //번역필수 모듈
		
		return {
			goBack,
			checkPasswordFormat,
			onClickPasswordChange,
			password,
			showPassword,
			confirmPassword,
			showConfirmPassword,
			isInEnglish,
			isInNumber,
			isInLength,			
			openErrorAlert,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			isSamePassword,
			t,
			i18n,
		}
	},
}
</script>
